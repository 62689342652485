import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';

const NotFoundPage = () => (
  <Layout>
    <div>
      <section className="breadcrumb-area">
        <div className="container text-center">
          <h1>Page Does Not Exist</h1>
        </div>
      </section>
      {/* End breadcrumb area */}
      {/* Start breadcrumb bottom area */}
      <section className="breadcrumb-botton-area">
        <div className="container">
          <div className="left pull-left">
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><i className="fa fa-caret-right" aria-hidden="true" /></li>
              <li>404</li>
            </ul>
          </div>
          <div className="right pull-right">
            <a href="#">
              <i className="fa fa-share-alt" aria-hidden="true" />
Share
            </a>
          </div>
        </div>
      </section>
      {/* End breadcrumb bottom area */}
      {/* Start 404 area */}
      <section className="not-found-area">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="not-found-content text-center">
                <h1>404</h1>
                <h3>OOPPS! THE PAGE YOU WERE LOOKING FOR, COULDN'T BE FOUND.</h3>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default NotFoundPage;
